import { useBreakpoint } from '@vivino/js-react-common-ui';
import {
  Anchor,
  BasicVintageWithBasicWine,
  BottleShot,
  BottleSize,
  ComponentSizes,
  RubyLibVintagePrice,
  VivinoRating,
  VivinoRatingSizes,
  isDefaultVintage,
  vintageAltText,
} from '@vivino/js-web-common';
import cx from 'classnames';
import React, { useContext, useEffect } from 'react';
import { track } from 'vivino-js/analytics';
import { WebApiHighlight } from 'vivino-js/api/highlights';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { MerchandizingCampaignContext } from 'vivino-js/context/MerchandizingContext';
import { getCartItemSource, getCartItemSourceProps } from 'vivino-js/helpers/cartItemSources';
import { vintageUrl, wineUrl } from 'vivino-js/vivinoUrls';
import Card from 'vivino-ui/atoms/Card';
import DiscountSplash from 'vivino-ui/atoms/DiscountSplash';
import LabelShot from 'vivino-ui/atoms/LabelShot';

import VFMScore from '@components/VFMScore/VFMScore';

import AddToCart from 'vivino-ui/components/AddToCart';
import CommunityReview, {
  CommunityReviewSkeleton,
} from 'vivino-ui/components/CommunityReviews/components/CommunityReview';
import EditorNote from 'vivino-ui/components/EditorNote/EditorNote';
import Highlight, { HighlightSkeleton } from 'vivino-ui/components/Highlight';
import SuperWineCardSection from 'vivino-ui/components/SuperWineCardSection';
import { SuperWineCardOptions } from 'vivino-ui/components/SuperWineCardSection/SuperWineCardSection';
import WineInfo from 'vivino-ui/components/WineInfo';

import styles from './wineCard.scss';

interface WineCardPropTypes {
  className?: string;
  editorNote?: {
    note: string;
    user: Partial<{
      [key: string]: string;
    }>;
  };
  review?: {};
  isLoadingReview?: boolean;
  highlight?: WebApiHighlight;
  isLoadingHighlight?: boolean;
  blurb?: string;
  price?: RubyLibVintagePrice;
  vintage: BasicVintageWithBasicWine;
  showOutOfStock?: boolean;
  useLabelShots?: boolean;
  isMasterWineCard?: boolean;
  size?: ComponentSizes;
  trackingProps?: { screen?: string; event: any; props?: {} };
  superWineCardOptions?: SuperWineCardOptions;
}
const WineCard = ({
  className,
  price,
  vintage,
  editorNote,
  review,
  isLoadingReview = false,
  highlight,
  isLoadingHighlight = false,
  blurb = '',
  showOutOfStock = true,
  useLabelShots = false,
  isMasterWineCard = false,
  size = ComponentSizes.Base,
  trackingProps,
  superWineCardOptions,
}: WineCardPropTypes) => {
  const typeId = vintage?.wine?.type_id;
  const cartItemSourceType = useContext(CartItemSourceContext);
  const campaignId = useContext(MerchandizingCampaignContext);
  const cartItemSourceData = getCartItemSource(cartItemSourceType, campaignId);
  const { isMobile } = useBreakpoint();

  const { wine } = vintage;

  useEffect(() => {
    if (trackingProps) {
      track(trackingProps);
    }
  }, []);

  const vintageAnchorProps = {
    href: isMasterWineCard ? wineUrl({ wine }) : vintageUrl({ vintage, price }),
    ...getCartItemSourceProps(cartItemSourceData),
  };

  const ratingProps = isMasterWineCard ? { wine } : { vintage };

  const addToCartProps = {
    price,
    seenVintageId: vintage.id,
    showOutOfStock,
    fullWidth: true,
    vintageYear: null,
  };

  if (isMasterWineCard && !isDefaultVintage(vintage)) {
    addToCartProps.vintageYear = vintage.year;
  }

  const vfmCategory = (price as any)?.vfm_category;
  const showVfMCategory = vfmCategory && vfmCategory.id > 0;

  // Render the large card only when size is Large and not mobile
  const finalSize =
    size === ComponentSizes.Large && !isMobile ? ComponentSizes.Large : ComponentSizes.Base;

  const renderVfM = () => (
    <div
      className={cx({
        [styles.bottomItemMargin]: finalSize !== ComponentSizes.Large,
        [styles.vfm_score]: finalSize !== ComponentSizes.Large,
      })}
    >
      <VFMScore category={vfmCategory} />
    </div>
  );

  const renderWineInfo = () => (
    <WineInfo vintage={vintage} isMasterWineCard={isMasterWineCard} vintageSize={finalSize} />
  );

  const renderHighlight = () => (
    <>
      {isLoadingHighlight && (
        <HighlightSkeleton className={cx(styles.bottomItemMargin, styles.highlight)} />
      )}
      {highlight && (
        <Highlight
          highlight={highlight}
          shownVintageId={vintage.id}
          className={cx(styles.bottomItemMargin, styles.highlight)}
        />
      )}
    </>
  );

  return (
    <div
      className={cx(className, styles.wineCard, styles[finalSize], {
        [styles.isSuperWineCard]: !!superWineCardOptions,
      })}
      data-testid="wineCard"
    >
      <Card className={cx(styles.wineCardContent)}>
        <Anchor className={styles.cardLink} {...vintageAnchorProps} data-testid="vintagePageLink">
          {superWineCardOptions && (
            <SuperWineCardSection
              size={finalSize === ComponentSizes.Base ? 'base' : 'large'}
              superWineCardOptions={superWineCardOptions}
              className={styles.superWineCard}
              withinWineCard
            />
          )}
          <div className={styles.topSection}>
            <div className={styles.bottleSection}>
              <DiscountSplash className={styles.splash} price={price} />
              {useLabelShots ? (
                <LabelShot
                  className={styles.labelShot}
                  vintage={vintage as any}
                  alt={vintageAltText(vintage)}
                  small
                />
              ) : (
                <BottleShot
                  size={BottleSize.Small}
                  className={styles.bottleShot}
                  image={vintage.image}
                  typeId={typeId}
                  alt={vintageAltText(vintage)}
                />
              )}
            </div>
            {finalSize === ComponentSizes.Large && (
              <div
                className={cx(styles.infoColumn, {
                  [styles.onlyWineInfo]: !showVfMCategory && !highlight && !isLoadingHighlight,
                })}
              >
                {renderWineInfo()}
                {!showVfMCategory && renderHighlight()}
                {showVfMCategory && renderVfM()}
              </div>
            )}
            <div className={styles.ratingAndAddtoCart}>
              <VivinoRating
                {...ratingProps}
                size={VivinoRatingSizes.Large}
                className={styles.rating}
              />
              <AddToCart {...addToCartProps} />
            </div>
          </div>
          {finalSize === ComponentSizes.Base && (
            <>
              <div className={cx(styles.grow)}>{renderWineInfo()}</div>
              {!showVfMCategory && renderHighlight()}
              {showVfMCategory && renderVfM()}
            </>
          )}
          {blurb && <div className={cx(styles.blurb, styles.bottomItemMargin)}>{blurb}</div>}
        </Anchor>
        {editorNote && (
          <EditorNote
            {...editorNote}
            vintage={vintage}
            price={price}
            className={styles.bottomItemMargin}
          />
        )}
        {isLoadingReview && (
          <div className={styles.bottomItemMargin}>
            <CommunityReviewSkeleton className={styles.review} />
          </div>
        )}
        {review && (
          <div className={styles.bottomItemMargin}>
            <CommunityReview review={review} vintageYear={vintage.year} className={styles.review} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default WineCard;
